<template>
  <section>
    <b-row class="mb-4" v-if="onlendings?.length">
      <b-col cols="12">
        <b-card>
          <b-card-title class="card-title d-flex justify-content-between"> 
            {{type === "review" ? 'Itens a revisar' : 'Itens conferidos'}} 
            <b-button
              class="btn"
              variant="outline-primary"
              @click="changeItemStatus"
              v-b-tooltip.hover :title="!selecteds?.length ? 'Selecione o(s) item(ns)' : ''"
              :disabled="!selecteds?.length"
            >
              Definir item como {{type === "checked" ? 'a revisar' : 'conferido'}} 
            </b-button>
          </b-card-title>

          <b-table-simple striped responsive>
            <tbody>
              <tr class="tr-header">
                <th>
                  <Check              
                    :tooltip="selectAll ? 'Desselecionar tudo': 'Selecionar tudo'"
                    :value="selectAll"
                    @change="handleSelectAll()"
                    :key="selectAll" 
                  />
                </th>
                <th>Data</th>
                <th>Item</th>
                <th>Perfil/Grau part</th>
                <th>Paciente</th>
                <th>Convênio</th>
                <th>Valor Proced.</th>
                <th>Valor Repasse</th>
                <th>Regra Aplicada</th>
                <th v-if="!['A pagar', 'Excluído', 'Finalizado'].includes(extract.status)"></th>
              </tr>

              <tr
                v-for="onlending in onlendings"
                :key="onlending.id"
                class="tr-header"
              >
                <td>
                  <Check              
                    :value="selecteds?.includes(onlending.id)"
                    @change="selectOnlending(onlending)"
                  />
                </td>
                <td>{{ moment(onlending.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ? onlending.item?.execution_date : onlending.item?.appointment?.start_datetime ).format('DD/MM/YYYY') }}</td>
                <td>{{ onlending.item?.item?.name }}</td>
                <td>{{ onlending.rule_definition?.profile?.name }}</td>
                <td>{{ 
                  onlending.origin_type === 'App\\Models\\TissGuide\\TissGuide' ?
                    (onlending.origin && onlending.origin.patient ? onlending.origin.patient.name : '-') :
                    (onlending.item && onlending.item.appointment && onlending.item.appointment.patient ?
                      onlending.item.appointment.patient.name : '-')
                }}</td>                                
                <td>{{ onlending.health_plan?.fantasy_name }}</td>
                <td>{{ parseNumberToMoney(onlending.total_value) }}</td>
                <td>{{ parseNumberToMoney(onlending.onlending_value) }}</td>
                <td>{{ 'Regra #'+ onlending.rule_definition.rule.number + ' - ' +  onlending?.rule_definition?.rule?.name }}</td>
                <td v-if="!['A pagar', 'Excluído', 'Finalizado'].includes(extract.status)">
                  <span class="text-center d-flex inline mt-1 mr-2">
                    <Trash 
                      class="icon-table trash"
                      v-can="'RepMedExt3'"
                      @click="onDeleteClick(onlending.id)"
                      v-b-tooltip.hover title="Remover item"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </b-table-simple>

          <div class="pagination-position">
            <b-pagination
              size="sm"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
            />
          </div>

          <b-row align-h="end">
          <span class="result-label mr-3">
            Valor total 
            <b> {{parseNumberToMoney(extract.total_value)}} </b>
          </span>

          <span class="result-label">
            Valor de repasse 
            <b> {{parseNumberToMoney(extract.onlending_value)}} </b>
          </span>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '@/modules/onlending/api'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '../../../../utils/localStorageManager'

export default {
  name: 'ItemsTable',
  components: {
    Check: () => import('@/components/General/Check'),
    Trash: () => import('@/assets/icons/trash.svg')
  },
  props: {
    extract: Object,
    updateOnledingStatus: Function,
    type: String,
    currentPage: Number,
    filters: Array,
    refreshTables: Number
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      onlendings: [],
      perPage: 10,
      totalRows: 0,      

      selecteds: [],
      selectAll: false,
      watchAllSelected: true,
      onlendingIds: []
    }
  },
  methods: {
    parseNumberToMoney,
    async getOnlendingsByExtractId() {
      const isLoading = this.$loading.show()
      const data = {
        extract_id: this.extract.id,
        clinic_id: this.clinic.id,
        filters: this.filters,
        type: this.type
      }
      await api.getOnlendingsByExtractId(this.currentPage ?? 1, data)
      .then(res => {  
        this.onlendings = res.data.data.data
        this.$emit('changePage', {type: this.type, page: res.data.data.current_page})
        this.perPage = res.data.data.per_page
        this.totalRows = res.data.data.total
        if(res?.data?.onlending_ids?.length) this.onlendingIds = res.data.onlending_ids
      })
      .catch(error => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    changeItemStatus(){
      this.updateOnledingStatus(this.selecteds, this.type === 'checked' ? 'A revisar' : 'Extrato')
      this.$emit('refreshAllTables')
    },
    changeSelectAll(val){
      this.selectAll = val
      this.watchAllSelected = !val
    }, 
    handleSelectAll() {
      this.selecteds = (this.watchAllSelected && !this.selectAll) ? this.onlendingIds : []
      this.changeSelectAll(this.watchAllSelected && !this.selectAll)
    },
    selectOnlending(onlending) {
      const index = this.selecteds.indexOf(onlending.id)
      if (index === -1) this.selecteds.push(onlending.id)
      else this.selecteds.splice(index, 1)
      this.changeSelectAll(this.selecteds?.length === this.onlendingIds?.length)
    },
    
    onDeleteClick(id) {
      this.$swal({
        icon: 'question',
        title: 'Remover item',
        html: `<div class="body-alert"><b>Essa ação não será desfeita</b>, deseja remover esse item do extrato?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          this.$emit('removeOnlendingFromExtract', id)
          setTimeout(() => {
            this.$emit('refreshAllTables')
          }, 300);
        }
      })
    }
  },
  watch: {
    currentPage(val) {     
      if(val) {
        this.$emit('changePage', {type: this.type, page: val})
        setTimeout(() => {
          this.getOnlendingsByExtractId()    
        }, 300);
      }
    },
    extract(extract) {      
      if(extract.id)this.getOnlendingsByExtractId()    
    },
    refreshTables() {      
      this.getOnlendingsByExtractId()    
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-table{
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .trash {
    fill: none;
    stroke: var(--states-red-soft);
  }
</style>

